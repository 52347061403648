import { render, staticRenderFns } from "./Call.vue?vue&type=template&id=1d40e684&scoped=true&"
import script from "./Call.vue?vue&type=script&lang=js&"
export * from "./Call.vue?vue&type=script&lang=js&"
import style0 from "./Call.vue?vue&type=style&index=0&id=1d40e684&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d40e684",
  null
  
)

export default component.exports