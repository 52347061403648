<template>
  <div class="position-relative">
    <img class="custom-icon" :src="call" />
  </div>
</template>
<script>
import call from "@/assets/icons/call.png";
export default {
  data() {
    return {
      call,
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.custom-icon {
    opacity: 0.2;
  height: 1.6rem;
  width: auto;
}
</style>
