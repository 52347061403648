<template>
  <div class="position-relative">
    <img class="custom-icon" :src="user" />
  </div>
</template>
<script>
import user from "@/assets/icons/user.png";
export default {
  data() {
    return {
      user,
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.custom-icon {
    opacity:0.2;
  height: 1.6rem;
  width: auto;
}
</style>
