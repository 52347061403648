<template>
  <div class="w-100 vh-100 ">
    <div class="custom-logo-icon" style="height:3rem">
      <MyLogo />
    </div>
    <div
      class="bg-color-white h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <div class="custom-box bg-color-white p-2 px-4 py-3">
        <div class="custom-heading font-butler color-black text-center">
          Create your account
        </div>
        <p class="custom-text text-center my-1 mb-1">
          Please create your account over here
        </p>
        <div class="custom-input-group d-flex mb-1">
          <input
            type="text"
            name=""
            id=""
            placeholder="Full Name"
            v-model="name"
          />
          <div
            class="custom-icon position-relative d-flex justify-content-center align-items-center"
          >
            <User />
          </div>
        </div>
        <div class="custom-input-group d-flex" style="margin-bottom:2px;">
          <input
            type="text"
            name=""
            id=""
            placeholder="Mobile Number"
            v-model="phoneNumber"
          />
          <div
            class="custom-icon position-relative d-flex justify-content-center align-items-center"
          >
            <Call />
          </div>
        </div>
        <div style="font-size:10px; cursor:pointer; color:navy;" class="d-flex justify-content-end mb-1" @click="openPopup">
          Verify now
        </div>
        <div class="custom-input-group d-flex mb-1">
          <input
            type="email"
            name=""
            id=""
            placeholder="Email address"
            v-model="email"
          />
          <div
            class="custom-icon position-relative d-flex justify-content-center align-items-center"
          >
            <Mail />
          </div>
        </div>

        <div class="custom-input-group d-flex mb-1">
          <input type="password" placeholder="Password" v-model="password" />
          <div
            class="custom-icon position-relative d-flex justify-content-center align-items-center"
          >
            <Lock />
          </div>
        </div>
        <div class="custom-input-group d-flex mb-1">
          <input
            type="password"
            placeholder="Confirm your passsword"
            v-model="confirmPassword"
            :class="confirmPasswordvalidationInput"
          />
          <div
            class="custom-icon position-relative d-flex justify-content-center align-items-center"
            :class="confirmPasswordvalidationIcon"
          >
            <Lock />
          </div>
        </div>

        <button
          class="custom-button btn-full-width color-white bg-color-blue text-uppercase my-2"
          @click="useFirebaseEmailSignup"
          v-if="!loading && isNumberVerified"
        >
          Sign Up
        </button>
        <button
          class="custom-button-gray btn-full-width color-white text-uppercase my-2"
          v-if="!loading && !isNumberVerified"
        >
          Sign Up
        </button>
        <button
          class="custom-button btn-full-width color-white bg-color-blue text-uppercase my-2"
          v-if="loading"
        >
          Loading ....
        </button>
        <div class="text-center">
          <span>Already have an account? </span>
          <router-link to="/pages/newlogin" class="color-blue">
            Sign in here
          </router-link>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
          <div class="custom-line"></div>
          <div class="mx-1">or</div>
          <div class="custom-line"></div>
        </div>
        <div class="d-flex justify-content-center">
          <div
            class="custom-icon-social position-relative d-flex justify-content-center align-items-center"
          >
            <img
              :src="facebookIcon"
              alt=""
              @click="useFirebaseFacebookSignup"
            />
          </div>
          <div
            class="custom-icon-social position-relative d-flex justify-content-center align-items-center"
          >
            <img :src="twitterIcon" alt="" />
          </div>
          <div
            class="custom-icon-social position-relative d-flex justify-content-center align-items-center"
          >
            <img :src="googleIcon" alt="" @click="useFirebaseGoogleSignup" />
          </div>
        </div>
      </div>
    </div>
    <div class="custom-overlay-for-popup" v-if="otpPopup">
      <div class="custom-verify-popup">
        <div style="text-align:center; font-size: 24px; font-weight:500; margin-bottom: 2rem;">
          Enter OTP
        </div>
        <div>
          <input
            type="text"
            name=""
            id=""
            placeholder="Enter OTP"
            v-model="otp"
            class="custom-input-popup"
          />
        </div>
        <div class="d-flex justify-content-end align-items-center py-1" style="color:blue;font-size:12px;">
          Resend OTP
        </div>
        <div class="d-flex justify-content-center">
          <button
            class="custom-button-popup btn-full-width color-white bg-color-blue text-uppercase mx-2"
            @click="verifyOtp"
          >
            Verify
          </button>
          <button
            class="custom-button-popup btn-full-width color-white bg-color-blue text-uppercase mx-2"
            @click="otpPopup = !otpPopup"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  firebaseEmailSignup,
  loginByFacebook,
  loginByGoogle,
  verifyEmail,
  userRegister,
} from "@/utils/api.js";
import facebookIcon from "@/assets/icons/facebook.png";
import twitterIcon from "@/assets/icons/twitter.png";
import googleIcon from "@/assets/icons/google.png";
import Mail from "@/views/Icons/Mail/Mail.vue";
import Lock from "@/views/Icons/Lock/Lock.vue";
import User from "@/views/Icons/User/User.vue";
import Call from "@/views/Icons/Call/Call.vue";


import MyLogo from '@/views/newcomponents/Logo/Logo.vue';

export default {
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      facebookIcon,
      twitterIcon,
      googleIcon,
      loading: false,
      name: "",
      phoneNumber: "",
      isNumberVerified:false,
      otp: "",
      otpPopup: false,
      realOTP: 123456,
    };
  },
  methods: {
    checkPasswordValidity() {
      if (this.password == this.confirmPassword) {
      }
    },

    validateForm() {
      if (
        this.name.trim() == null ||
        this.name.trim() == undefined ||
        this.name.trim() == ""
      ) {
        this.$toasted.error("Please type a valid name.", {
          position: "top-center",
          duration: 5000,
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return false;
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.$toasted.error("Please type correct email.", {
          position: "top-center",
          duration: 5000,
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return false;
      }

      if (
        this.password.trim() == null ||
        this.password.trim() == undefined ||
        this.password.trim() == ""
      ) {
        this.$toasted.error("Please type a valid password.", {
          position: "top-center",
          duration: 5000,
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return false;
      }
      if (this.password !== this.confirmPassword){
        this.$toasted.error("Passwords do not match", {
          position: "top-center",
          duration: 5000,
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return false;
      }
      if (
        this.password.trim().length<8
      ) {
        this.$toasted.error("Password must be atleast 8 chars long.", {
          position: "top-center",
          duration: 5000,
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return false;
      }

      return true;
    },

    openPopup() {
      if(this.phoneNumber)
      this.otpPopup = true;
    },

    verifyOtp(){
      console.log(this.otp);
      if(this.otp == this.realOTP){
        this.otpPopup = false;
        this.isNumberVerified = true;
        this.$toasted.success("Number verified successfully", {
          position: "top-center",
          duration: 5000,
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }else{
        this.$toasted.error("Please enter correct OTP", {
          position: "top-center",
          duration: 5000,
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }
    },

    //Method to register using email to firebase

    async useFirebaseEmailSignup() {

      if(!this.validateForm()){
        return;
      }
      // const data = await firebaseEmailSignup(this.email, this.password);
      // console.log("Firebase Email Signup:", data);

      this.loading = true;

        const email = this.email;
        const password = this.password;
        const name = this.name;

        const apiDetails = {
          email: email,
          password: password,
          is_firebase: false,
          ph_no: this.phoneNumber,
          name: name,
          user_type: "owner",
        };

        try {
          const data = await userRegister(apiDetails);

          if (data.success) {
            console.log("Entering here 1. Success!");

            console.log(data);
            //this.$router.push('/newlogin');
            this.$router.push({path:"/pages/verify-email", query: {name: this.name, email: this.email}});
          } else {
            console.log("Entering here 2");
            console.log(data, "Error");
          }
        } catch (err) {
          console.log(data);
        }
      this.loading = false;
    },

    // Method to register using Facebook to firebase

    async useFirebaseFacebookSignup() {
      try {
        const data = await loginByFacebook();

        if (typeof data.err !== undefined) {
          const name = data.displayName;
          const email = data.email;
          const phoneNumber = data.phoneNumber ? data.phoneNumber : "";

          const apiDetails = {
            name,
            is_firebase: true,
            email,
            ph_no: phoneNumber,

            user_type: "owner",
            password: "",
          };

          console.log("Printing API deets here", apiDetails);
          const resData = await userRegister(apiDetails);
          console.log("ResData :", resData);
          if (resData.success) {
            this.$router.push("/pages/newlogin");
          } else {
            //User already exists
            //this.$router.go();
            console.log("kaam nahi ho raha hai!");
          }
        }
      } catch (err) {
        console.log(err);
        console.log("Outside this error!");
      }
    },

    // Method to register using Google to firebase
    async useFirebaseGoogleSignup() {
      try {
        const data = await loginByGoogle();
        if (typeof data.err !== undefined) {
          const name = data.displayName;
          const email = data.email;
          const phoneNumber = data.phoneNumber ? data.phoneNumber : "";

          const apiDetails = {
            name,
            is_firebase: true,
            email,
            ph_no: phoneNumber,

            user_type: "owner",
            password: "",
          };

          console.log("Printing API deets here", apiDetails);
          const resData = await userRegister(apiDetails);
          console.log("ResData :", resData);
          if (resData.success) {
            this.$router.push("/pages/newlogin");
            console.log("kaam ho raha hai!");
          } else {
            //User already exists

            //this.$router.go();
            console.log("kaam nahi ho raha hai!");
          }
        } else {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        console.log("This is the outside case");
      }
    },
  },
  watch: {
    confirmPassword: function() {
      if (this.confirmPassword.length > 0) {
        this.checkPasswordValidity();
      } else {
      }
    },
  },
  computed: {
    confirmPasswordvalidationInput() {
      if (
        this.password == this.confirmPassword &&
        this.confirmPassword.length > 0
      ) {
        return "custom-border-green";
      } else if (
        this.password != this.confirmPassword &&
        this.confirmPassword.length > 0
      ) {
        return "custom-border-red";
      } else {
        return "";
      }
    },
    confirmPasswordvalidationIcon() {
      if (
        this.password == this.confirmPassword &&
        this.confirmPassword.length > 0
      ) {
        return "custom-border-green-icon";
      } else if (
        this.password != this.confirmPassword &&
        this.confirmPassword.length > 0
      ) {
        return "custom-border-red-icon";
      } else {
        return "";
      }
    },
  },
  components: {
    Mail,
    Lock,
    MyLogo,
    User,
    Call,
  },
};
</script>

<style lang="scss" scoped>
.custom-border-green {
  border: 1px solid green !important;
  border-right: none !important;
}
.custom-border-red {
  border: 1px solid #ea5455 !important;
  border-right: none !important;
}

.custom-border-green-icon {
  border: 1px solid green !important;
}
.custom-border-red-icon {
  border: 1px solid #ea5455 !important;
}
.custom-box {
  height: 44rem;
  width: 30rem;

  box-shadow: 0px 0px 20px -2px rgba(#111, 0.1);
  .custom-heading {
    font-size: 2.3rem;
    font-weight: 500;
  }
  .custom-text {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  .custom-button {
    transition: 0.3s ease;
    &:hover {
      box-shadow: 0px 10px 20px -5px rgba(#0d6efd, 0.5);
      transform: translateY(-1px);
    }
  }
  .custom-button-gray {
    transition: 0.3s ease;
    background-color: rgba(#111, 0.3);
  }
  .custom-input-group {
    height: 2.4rem;
    position: relative;
    input {
      width: 85%;
      height: 100%;
      border: 1.3px solid rgba(#111, 0.3);
      border-right: 0px;
      border-radius: 5px 0px 0px 5px;
      outline: none;
      padding: 0 0.5rem;
      font-size: 0.9rem;
      &::placeholder {
        font-weight: 400;
        color: rgba(#111, 0.3);
        font-size: 0.9rem;
      }
    }
    .custom-icon {
      width: 15%;
      border-radius: 0px 5px 5px 0px;
      border: 1.3px solid rgba(#111, 0.3);
    }
  }
  .custom-line {
    width: 45%;
    height: 1px;
    background-color: rgba(#111, 0.3);
  }
  .custom-icon-social {
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 3px;
    margin-top: -1rem;
    margin-right: 0.5rem;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    img {
      height: 60%;
      width: auto;
    }
    &:nth-child(1) {
      background-color: navy;
    }
    &:nth-child(2) {
      background-color: deepskyblue;
    }
    &:nth-child(3) {
      background-color: red;
    }
  }
}
.custom-logo-icon{
  height: 5rem;
  width: 9rem;
  position: absolute;
  top: 5%;
  left:10%;
  z-index: 100;
}
.custom-overlay-for-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-verify-popup{
  height: 20rem;
  width: 40rem;
  max-width: 95%;
  background: #fff;
  border-radius: 5px;
  padding: 2rem 3rem;
}
.custom-input-popup{

    width: 100%;
    height: 4rem;
    text-align: center;
    border: 1.3px solid rgba(#111, 0.3);
    border-radius: 5px 5px 5px 5px;
    outline: none;
    padding: 0 0.5rem;
    font-size: 1.5rem;
    &::placeholder {
      font-weight: 400;
      color: rgba(#111, 0.3);
      font-size: 1.5rem;
    }
}
</style>
